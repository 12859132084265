const resource = "rhinov-book-start";

setTimeout(async ()=>{

    await killWorker();

    await window.loadPrimaryFile(resource);

},0)

window.loadPrimaryFile = async (_src) => {

    return new Promise((_resolve,_reject)=>{

        var script = document.createElement('script');
        script.onload = async function () {
            _resolve();
        };

        script.src = "http://localhost:9000/"+_src+".js?vt=3.0.6";
        
        document.head.appendChild(script); //or something of the likes


    });

}

const killWorker = async () => {

    if(navigator.serviceWorker !== undefined){

        var registration = await navigator.serviceWorker.getRegistration();

        if(registration){
    
            await registration.unregister()
    
        }

    }

}